<template>
  <div
    class="container relative mx-auto mb-60 flex h-full max-w-xl flex-col md:pt-6 2xl:pt-32"
  >
    <div class="px-2 py-8">
      <div class="mx-auto block w-full text-center">
        <SendTypography
          as="h1"
          color="text-send-almost-black"
          size="md"
          variant="heading"
          weight="semibold"
        >
          {{ $t("start_new_challenge") }}
        </SendTypography>
      </div>

      <div class="mx-auto mt-40 block w-full text-center">
        <SendTypography
          as="h2"
          color="text-send-almost-black"
          size="xs"
          variant="heading"
          weight="medium"
        >
          {{ $t("create_challenge_page.what_challenge_subtitle") }}
        </SendTypography>
      </div>

      <div class="mx-auto mt-9 block w-full">
        <div class="flex w-full justify-center space-x-0.5 text-xl">
          <span class="text-send-label-2-purple">‘</span>
          <input
            v-model="v.name.$model"
            :class="{
              '!text-red-500': v.name.$errors.length && v.name.$dirty,
            }"
            :placeholder="$t('my_challenge_title')"
            class="placeholder-text-send-grey w-fit border-none bg-transparent text-center text-xl font-medium text-send-label-2-purple focus:outline-none focus:ring-2 focus:ring-send-label-2-purple focus:ring-opacity-30"
          />
          <span class="text-send-label-2-purple">’</span>
        </div>
        <div
          class="mt-4 flex justify-center space-x-2 align-middle text-xl font-medium text-send-almost-black"
        >
          <span>for</span>
          <div class="flex w-fit justify-center space-x-0.5 align-middle">
            <span>€</span>
            <input
              v-model="v.amount.$model"
              :class="{
                '!text-red-500': v.amount.$errors.length && v.amount.$dirty,
              }"
              :style="computedAmountInputWidth"
              class="placeholder-text-send-grey max-w-xs border-none bg-transparent px-1 text-left text-xl font-medium text-send-label-2-purple focus:outline-none focus:ring-2 focus:ring-send-label-2-purple focus:ring-opacity-30"
              placeholder="0.00"
              type="number"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="fixed bottom-0 left-0 right-0 mx-auto max-w-xl border-0 border-t border-t-send-grey-60 bg-send-grey-bg py-8 lg:w-[calc(100%-320px)] lg:translate-x-[27%]"
    >
      <div class="grid w-full grid-cols-2 space-x-4 px-2">
        <SendBaseButton
          :label="$t('create_challenge_page.button_cancel')"
          size="sm"
          variant="primary"
          @click="newChallengeStore.flush()"
        />
        <SendBaseButton
          :disabled="!newChallengeStore.isStep1Valid()"
          :label="$t('create_challenge_page.button_next')"
          size="sm"
          variant="action"
          @click="navigateToStep2"
        />
      </div>

      <SendStepIndicator
        :current-step="1"
        :total-steps="3"
        class="mb-0 mt-8 block"
        size="md"
      ></SendStepIndicator>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useVuelidate from "@vuelidate/core";
import {
  maxLength,
  minLength,
  minValue,
  numeric,
  required,
} from "@vuelidate/validators";
import { computed, onBeforeMount, onMounted, toRefs, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import SendBaseButton from "~/components/partials/SendBaseButton.vue";
import SendStepIndicator from "~/components/partials/SendStepIndicator.vue";
import SendTypography from "~/components/partials/SendTypography.vue";
import { useCreateChallengeStore } from "~/stores/createChallenge";

// Store and Router
const newChallengeStore = useCreateChallengeStore();
const router = useRouter();
const route = useRoute();

// Reactive References
const { name, amount, timestamp } = toRefs(newChallengeStore);
const {
  challengedUsername,
  challengeMultipleFriends,
  challengedUserEmailsString,
} = toRefs(newChallengeStore);

// Validation
const v = useVuelidate(
  {
    name: { required, maxLength: maxLength(100), minLength: minLength(3) },
    amount: { required, numeric, greaterThanZero: minValue(0.1) },
  },
  { name, amount }
);

// Computed Properties
const computedAmountInputWidth = computed(() => {
  let width = (amount.value ? amount.value.toString().length : 0) * 8 + 70;
  if (width > 200) width = 200;
  return `width: ${width}px`;
});

// Navigation
function navigateToStep2() {
  router.push("/challenge/create/step-2");
}

// Lifecycle Hooks
onBeforeMount(() => {
  const difference = Math.abs((Date.now() - timestamp.value) / 60000);
  if (difference > 15) {
    newChallengeStore.flush();
  }
});

onMounted(() => {
  let initialLoad = true;
  watch(newChallengeStore, () => {
    if (initialLoad) {
      initialLoad = false;
    } else {
      timestamp.value = Date.now();
    }
  });

  if (route.query?.username) {
    challengedUsername.value = route.query.username as string;

    if (
      challengeMultipleFriends.value ||
      (challengedUserEmailsString.value &&
        challengedUserEmailsString.value?.length > 0)
    ) {
      challengeMultipleFriends.value = false;
      challengedUserEmailsString.value = undefined;
    }
  }
});
</script>

<style scoped></style>
